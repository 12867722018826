<template lang="pug">
  .onboarding-buttons.mt-4
    .onboarding-button.my-3.py-1(v-for="item in items")
      .onboarding-button-inner.d-flex.justify-content-between(@click="$emit('clicked', item)" style="max-height: 4.6875rem")
        img.onboarding-button-figure(v-if="figureBase" :src="require(`@/assets/admin${figureBase}/${item}.svg`)")
        .onboarding-button-title.d-flex(:class="titleCls")
          span(v-if="!htmlPrint") {{ $t(`${translationKeyBase}.${item}`) }}
          span(v-else v-html="$t(`${translationKeyBase}.${item}`)")
</template>

<script>
  export default {
    name: 'OnboardingButtonGroup',
    props: {
      items: { type: Array, required: true },
      translationKeyBase: { type: String, required: true },
      figureBase: { type: String },
      htmlPrint: { type: Boolean, default: false },
    },
    computed: {
      titleCls() {
        return {
          'justify-content-center': !this.figureBase,
          'ml-2 pr-1': this.figureBase,
        };
      },
    },
  };
</script>

<style lang="sass">
  .onboarding-button
    &-inner
      border: 1px solid #D8DDE1
      border-radius: 6px
      align-items: center
      height: 4.6875rem
    &-figure ~ &-title
      max-width: 60%
      font-size: 1.0625rem
      display: flex
      align-items: center
</style>
